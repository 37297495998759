import React from "react"
import ComingSoon from "../components/ComingSoon"

function Contact() {
    return(
        <div>
            <h1>CONTACT</h1>
            <ComingSoon></ComingSoon>
        </div>
    )
}

export default Contact