import React from "react"
import ComingSoon from "../components/ComingSoon"

function About() {
    return(
        <div>
            <h1>ABOUT ME</h1>
            <ComingSoon></ComingSoon>
        </div>
    )
}

export default About