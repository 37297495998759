import React from "react"
import Photoboard from "../components/Photoboard"

function Home() {
    return(
        <div>
            <Photoboard />
        </div>
    )
}

export default Home